.loader-container {
  text-align: center;
}

.loader {
  border: 8px solid rgba(0, 123, 255, 0.3);
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
