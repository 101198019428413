*,
*::before,
*::after {
  box-sizing: border-box;
}
.chat-show {
  text-align: start;
  text-wrap: wrap;
  word-wrap: break-word;
  border: 1px solid grey;
  /* background-color: #1553D1; */
  border-radius: 3px;
  padding: 5px 10px;
  color: lightgrey;
  font-size: 1.2rem;
  position: fixed;
  bottom: 1.75rem;
  left: 4rem;
  z-index: 1;
  max-width: 500px;
}
.chat {
  margin-left: auto;
  padding: 61px 15px 0px 15px;
  margin-bottom: 110px;
  max-width: 100%;
  max-height: calc(100vh - 61px);
  overflow: auto;
  margin-top: 65px;
}

.background-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  /* padding: 50px 150px; */
}

img {
  object-fit: contain;
}

.chat-message {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.warning-text {
  font-size: 12px;
}

.padding-x {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .font-button {
    width: 35px;
    height: 35px;
  }
  .background-wrapper {
    padding: 0px;
    overflow: hidden;
  }
  .header-text {
    font-size: 24px;
  }
  .header-logo {
    display: none;
  }

  /* .avatar-input {
    width: 45%;
  } */
  .video-tag {
    transform: scale(2);
  }
}

@media screen and (max-width: 425px) {
  .font-button {
    width: 40px;
    height: 40px;
  }
  .warning-text {
    font-size: 9px;
  }
  .input-field {
    flex-grow: revert;
  }
  .header-logo {
    display: none;
  }
  .header-text {
    font-size: 16px;
  }
  .background-wrapper {
    padding: 0px;
  }
  .avatar-input {
    width: min-content;
  }
  .video-tag {
    transform: scale(2.5);
  }
  .padding-x {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Modal styles */
.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  backdrop-filter: blur(15px);
}
.modal video {
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  margin-bottom: 10px;
}
/* Modal content */
.modal-content {
  background-color: #ffffff40;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

/* Button styles */
#openModalBtn,
#modalButton {
  background-color: #757575;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#openModalBtn:hover,
#modalButton:hover {
  background-color: #333;
}
.modal-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
